module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AgExpert","short_name":"AgExpert","description":"Software designed for Canadian agriculture.","lang":"en","start_url":"/en/","background_color":"#ffffff","theme_color":"#42806d","display":"standalone","icon":"src/svgs/favicon.svg","localize":[{"start_url":"/fr/","lang":"fr","name":"AgExpert","short_name":"AgExpert","description":"Logiciels conçus pour l’agriculture canadienne."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7df3a365773a27d119529384f88e0a0a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WXNP4PRT","includeInDevelopment":false,"routeChangeEventName":"route_change","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
